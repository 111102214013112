import { motion } from "framer-motion";

export function Example() {
  return (
    <motion.div
      style={{
        width: 150,
        height: 150,
        borderRadius: 30,
        backgroundColor: "#fff"
      }}
      animate={{ scale: 1.5 }}
      transition={{ type: "spring", damping: 3, delay: 0.3 }}
    />
  );
}
